export class LangKey {
  static UpdateDialogChecking = 'updateDialog.checking';
  static UpdateDialogDownloading = 'updateDialog.downloading';
  static UpdateDialogInstalling = 'updateDialog.installing';
  static UpdateDialogInstalled = 'updateDialog.installed';

  static AuthHeader = 'Auth.header';
  static AuthHeader2 = 'Auth.header2';
  static AuthTitle = 'Auth.title';
  static AuthTitle2 = 'Auth.title2';
  static AuthClient = 'Auth.client';
  static AuthLogin = 'Auth.login';
  static AuthPassword = 'Auth.password';
  static AuthLoginButton = 'Auth.loginButton';
  static AuthLogoutButton = 'Auth.logoutButton';
  static AuthLogoutConfirm = 'Auth.logoutConfirm';

  static ProjectsTitle = 'projects.title';
  static ProjectsList = 'projects.list';
  static ProjectsMap = 'projects.map';
  static ProjectsStandsCount = 'projects.standsCount';
  static ProjectsYouStandsCount = 'projects.youStandsCount';
  static ProjectsArea = 'projects.area';
  static ProjectsLoading = 'projects.loading';
  static ProjectsDownloadMap = 'projects.downloadMap';
  static ProjectsOpenProject = 'projects.openProject';
  static ProjectsOpenMap = 'projects.openMap';
  static ProjectsOpenEditor = 'projects.openEditor';
  static ProjectsOpenStand = 'projects.openStand';
  static ProjectsAddNewStand = 'projects.addNewStand';
  static ProjectsRemoveStand = 'projects.removeStand';
  static ProjectsSearchClient = 'projects.search.client';
  static ProjectsSearchEstate = 'projects.search.estate';
  static ProjectsSearchEstateCode = 'projects.search.estateCode';

  static ProjectTitle = 'project.title';
  static ProjectRemoveStandConfirm = 'project.removeStandConfirm';

  static TasksTitle = 'Tasks.title';
  static TaskAssigned = 'Tasks.assigned';
  static TasksSortId = 'Tasks.sort.id';
  static TasksSortStatus = 'Tasks.sort.status';
  static TasksSortDateAsc = 'Tasks.sort.dateAsc';
  static TasksSortDateDesc = 'Tasks.sort.dateDesc';

  static TaskTitle = 'task.title';
  static TaskInfo = 'task.info';
  static TaskStand = 'task.stand';
  static TaskNotes = 'task.notes';
  static TaskMap = 'task.map';
  static TaskStatusAssigned = 'task.status.assigned';
  static TaskStatusOnGoing = 'task.status.onGoing';
  static TaskStatusExecuted = 'task.status.executed';

  static FreeGeometryTitle = 'freeGeometry.title';
  static FreeGeometryInfo = 'freeGeometry.info';
  static FreeGeometryTooltipPerson = 'freeGeometry.tooltip.person';
  static FreeGeometryTooltipGroup = 'freeGeometry.tooltip.group';

  static StandArea = 'stand.area';
  static StandInfo = 'stand.info';
  static StandStandInfo = 'stand.standInfo';

  static NotesTitleAdd = 'notes.titleAdd';
  static NotesTitleEdit = 'notes.titleEdit';
  static NotesMessage = 'notes.message';
  static NotesGps = 'notes.gps';
  static NotesAccuracy = 'notes.accuracy';

  static MapBaseLayersLoisto = 'map.baseLayers.loisto';
  static MapBaseLayersOsm = 'map.baseLayers.osm';
  static MapBaseLayersMbStreets = 'map.baseLayers.mbStreets';
  static MapBaseLayersMpSatellite = 'map.baseLayers.mpSatellite';
  static MapBaseLayersMpTerrain = 'map.baseLayers.mpTerrain';
  static MapBaseLayersMpDark = 'map.baseLayers.mpDark';
  static MapBaseLayersMpLight = 'map.baseLayers.mpLight';
  static MapMwsLayersTitle = 'map.mwsLayers.title';
  static MapMwsLayersNoBgr = 'map.noBgr';
  static MapMwsLayersAerialFci = 'map.mwsLayers.aerialFci';
  static MapMwsLayersChm = 'map.mwsLayers.chm';
  static MapMwsLayersAerialTci = 'map.mwsLayers.aerialTci';
  static MapMwsLayersDsm = 'map.mwsLayers.dsm';
  static MapMwsLayersAnt = 'map.mwsLayers.ant';
  static MapMwsLayersHarvest = 'map.mwsLayers.harvest';
  static MapMwsLayersBorder = 'map.mwsLayers.border';
  static MapVectorLayersTitle = 'map.vectorLayers.title';
  static MapVectorLayersBorder = 'map.vectorLayers.border';
  static MapVectorLayersOsd = 'map.vectorLayers.osd';
  static MapVectorLayersCameraApp = 'map.vectorLayers.cameraApp';
  static MapVectorLayersCameraCom = 'map.vectorLayers.cameraCom';
  static MapVectorLayersSpecFeature = 'map.vectorLayers.specFeature';
  static MapVectorLayersBowling = 'map.vectorLayers.bowling';
  static MapVectorLayersMki = 'map.vectorLayers.mki';

  static DownloadMapsTitle = 'downloadMaps.title';
  static DownloadMapsDownloadAllButton = 'downloadMaps.downloadAllButton';
  static DownloadMapsMapType = 'downloadMaps.mapType';

  static FormsErrorRequired = 'forms.error.required';
  static FormsErrorNumber = 'forms.error.number';
  static FormsErrorMin = 'forms.error.min';
  static FormsErrorMax = 'forms.error.max';
  static FormsErrorReqFields = 'forms.error.reqFields';
  static FormsErrorReqMaxStrLength = 'forms.error.maxStrLength';
  static FormsErrorReqMinStrLength = 'forms.error.minStrLength';

  static SettingsTitle = 'settings.title';
  static SettingsLanguage = 'settings.language';
  static SettingsUnitAge = 'settings.unitAge';
  static SettingsAllStand = 'settings.allStand';
  static SettingsAgeUnitYear = 'settings.ageUnit.year';
  static SettingsAgeUnitMonth = 'settings.ageUnit.month';

  static DynamicPropertyAddValue = 'dynamicProperty.add';
  static DynamicPropertyInfoSaveCategoryNext = 'dynamicProperty.infoSaveCategoryNext';
  static DynamicPropertyInfoCreateAndClose = 'dynamicProperty.infoCreateAndClose';

  static CommonOk = 'common.ok';
  static CommonCancel = 'common.cancel';
  static CommonClear = 'common.clear';
  static CommonSave = 'common.save';
  static CommonDelete = 'common.delete';
  static CommonUnknownCriticalError = 'common.unknownCriticalError';
  static CommonUnknownSynchronizing = 'common.synchronizing';
  static CommonUnknownSynchronized = 'common.synchronized';
  static CommonUnknownSyncIntoDB = 'common.syncIntoDB';
  static CommonUnknownMapDownloading = 'common.mapDownloading';

  static ConfirmDefault = 'confirm.default';
  static ConfirmDelete = 'confirm.delete';
  static ConfirmYes = 'confirm.yes';
  static ConfirmNo = 'confirm.no';

  static UnitsYear = 'units.year';
  static UnitsYearPlural = 'units.year_plural';
  static UnitsMonth = 'units.month';
  static UnitsMonthPlural = 'units.month_plural';
  static UnitsDay = 'units.day';
  static UnitsDayPlural = 'units.day_plural';
  static UnitsCount = 'units.count';
  static UnitsCountHa = 'units.count/Ha';
  static UnitsMm = 'units.mm';
  static UnitsCm = 'units.cm';
  static UnitsDm = 'units.dm';
  static UnitsM = 'units.m';
  static UnitsKm = 'units.km';
  static UnitsCm2 = 'units.cm2';
  static UnitsM2 = 'units.m2';
  static UnitsHa = 'units.ha';
  static UnitsKm2 = 'units.km2';
  static UnitsDm3 = 'units.dm3';
  static UnitsM3 = 'units.m3';
  static UnitsM3Ha = 'units.m3/ha';
  static UnitsTon = 'units.ton';
  static UnitsKg = 'units.kg';
  static UnitsPercent = 'units.percent';

  static OfflineGetOffline = 'offline.getOffline';
  static OfflineGetOnline = 'offline.getOnline';
  static OfflineToggleStatus = 'offline.toggleStatus';
  static OfflineSelectLayersTitle = 'offline.selectLayersTitle';
  static OfflineBaseLayer = 'offline.baseLayer';
  static OfflineLayers = 'offline.layers';
  static OfflineOpenStandData = 'offline.openStandData';
  static OfflineEstateBorders = 'offline.estateBorders';
  static OfflineDownload = 'offline.download';
  static OfflineCancel = 'offline.cancel';
  static OfflineNoMapLoaded = 'offline.noMapLoaded';
  static OfflineMapLoadedWithLayers = 'offline.mapLoadedWithLayers';
  static OfflineDownloadMapHasBeenSaved = 'offline.downloadMapHasBeenSaved';
  static OfflineSelectAll = 'offline.selectAll';
  static OfflineUnselectAll = 'offline.unselectAll';
  static OfflineSaveConfirm = 'offline.saveConfirm';
  static OfflineEditor = 'offline.editor';
  static OfflineNotFound = 'offline.notFound';
  static OfflineStandBorderColor = 'offline.standBorderColor';
  static OfflineSaving = 'offline.saving';

  static MapElementsTitle = 'mapElements.title';
  static GpsCoordErrorMessage = 'gpsMenu.coordErrorMessage';
  static GpsSaveGpsLocation = 'gpsMenu.saveGpsLocation';
  static GpsStartTrackRecording = 'gpsMenu.startTrackRecording';
  static GpsPauseTrackRecording = 'gpsMenu.pauseTrackRecording';
  static GpsRestartTrackRecording = 'gpsMenu.restartTrackRecording';
  static GpsStopTrackRecording = 'gpsMenu.stopTrackRecording';
  static GpsTrackPointsErrorMessage = 'gpsMenu.trackPointsErrorMessage';
}
