import ResultSnackBar from '../app/ResultSnackBar';
import { LangKey } from '../../LangKey';
import { LineString } from 'geojson';
import * as mapboxgl from 'mapbox-gl';

export const MapboxDrawLine = (map: mapboxgl.Map, points: number[][], id: number) => {
  if (!points.length) {
    ResultSnackBar.error(LangKey.GpsTrackPointsErrorMessage);
    return;
  }

  if (points.length < 2) return;

  const obj: LineString = { coordinates: points, type: 'LineString' };

  const lineId = `lineId${id}`;
  const layerId = `layerId${id}`;

  const source = map.getSource(lineId);
  if (source) {
    map.removeLayer(layerId);
    map.removeSource(lineId);
  }
  map.addSource(lineId, {
    type: 'geojson',
    data: {
      type: 'Feature',
      properties: {},
      geometry: obj,
    },
  });

  map.addLayer({
    id: layerId,
    type: 'line',
    source: lineId,
    layout: {
      'line-join': 'round',
      'line-cap': 'round',
    },
    paint: {
      'line-color': '#888',
      'line-width': 8,
    },
  });

  /*const wkt = UtilsMap.geoJSONToGeom(obj);
  const geoJSON = UtilsMap.geomToGeoJSON({ wkt });

  if (obj.type === 'LineString' && gpsModel.lineId) {
    editor.draw.delete(gpsModel.lineId);
  }

  const element = editor.draw.add(geoJSON);
  gpsModel.lineId = element[0];*/
};
