import * as React from 'react';
import { default as useResizeAware } from 'react-resize-aware';
import { Map as BMap } from 'mapbox-gl';

type ResizeListenerProps = {
  map?: BMap;
};

// ResizeListener is a separate component
// to avoid unnecessary renders of parent map
const ResizeListener = (props: ResizeListenerProps) => {
  const { map } = props;
  const [resizeListener, sizes] = useResizeAware();
  React.useEffect(
    () => {
      if (!map) return;
      map.resize();
    },
    [map, sizes.width, sizes.height],
  );
  return <>{resizeListener}</>;
};

export default ResizeListener;
