import * as React from 'react';
import { observer } from 'mobx-react';
import { Map as BMap, ScaleControl } from 'mapbox-gl';
import * as turf from '@turf/turf';
import clsx from 'clsx';
import {
  Action,
  EditorStand,
  getAllStandAroundEstate,
  StandBorderColorChooser,
  ViewEditorMap,
} from '@simosol/stands-map-editor';

import Project from '../../models/Project';

import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { Theme } from '@mui/material/styles';
import {
  OwnLocationButton,
  useBaseLayer,
  useMapDefaultStyles,
  useOwnLocationMarker,
  usePositionLock,
  usePositionWatcher,
} from '@simosol/stands-map';
import useEditorStands from '../../models/editor/useEditorStands';
import { appModel } from '../../models/App';
import { Feature, MultiPolygon, Polygon } from 'geojson';
import { UtilsMap } from '../../utils/UtilsMap';
import ResizeListener from './ResizeListener';
import Compass from './compass/Compass';
import { DescriptorType } from '@simosol/iptim-data-model';
import baseLayersETapio from '../map/etapio/baseLayersETapio';
import { runInAction } from 'mobx';
import { SyncStatus } from '../../models/Stands';
import DB from '../../models/DB';
import LayersControl from '../map/LayersControl';
import { LangKey } from '../../LangKey';

const CONTAINER = 'editor';

export type NewStandFromEditor = {
  id: string | number;
  estate: string;
  number: number;
  sourceStandId: number;
  mainGroup: number;
  planningArea: string;
  geom: { wkt: string };
  isInUserOfflineList: boolean;
};

const useStyles = makeStyles((theme: Theme) => createStyles({
  container: {
    height: '100%',
    position: 'relative',
  },
  resizeCont: {
    position: 'relative',
    display: 'flex',
    overflowY: 'hidden',
    overflowX: 'hidden',
  },
  map: {
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    position: 'absolute',
  },
  editor: {
    left: 0,
    right: 0,
    top: 30,
    bottom: 0,
    userSelect: 'none',
    position: 'absolute',
  },
  containerButtons: {
    position: 'absolute',
    right: theme.spacing(1),
    bottom: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
      top: theme.spacing(7),
      right: 'auto',
    },
  },
  buttonGPS: {
    position: 'absolute',
    top: theme.spacing(8),
    right: theme.spacing(1),
    borderRadius: theme.spacing(3),
    [theme.breakpoints.down('md')]: {
      top: theme.spacing(7),
      right: theme.spacing(.5),
    },
  },
  borderChooser: {
    position: 'absolute',
    top: theme.spacing(15),
    right: theme.spacing(1),
    borderRadius: theme.spacing(3),
    [theme.breakpoints.down('md')]: {
      right: theme.spacing(.5),
      top: theme.spacing(20),
    },
  },
}));

type Props = {
  project: Project;
  selectedStand?: string;
  waitingSave: boolean;
  saveComplete: (stands: NewStandFromEditor[]) => void;
  changeActions: (action: Action[]) => void;
};

const EditorMap = observer((props: Props) => {
  const { project, selectedStand, waitingSave, saveComplete, changeActions } = props;
  const classes = useStyles();

  const [map, setMap] = React.useState<BMap>();
  const [stands, setStands] = React.useState<EditorStand[]>([]);

  const positionWatcher = usePositionWatcher();
  const [positionLock, setPositionLock] = usePositionLock(map, positionWatcher);
  const mapClasses = useMapDefaultStyles();
  useOwnLocationMarker(map, positionWatcher, mapClasses.locationMarker);

  React.useEffect(
    () => {
      setMap(new BMap({
        container: CONTAINER,
        style: 'temp/loisto_styles_v3.json',
        accessToken: process.env.REACT_APP_MAPBOX_TOKEN,
        minZoom: appModel.isOffline ? 12 : undefined,
        center: [23.7278, 61.4951],
        zoom: 15,
      }));
      setStands(useEditorStands(project.stands.filter(s => s.syncStatus !== SyncStatus.del)));
    },
    [],
  );
  React.useEffect(
    () => {
      if (!map) return;
      if (project.stands.length > 0) {
        const allFeatureCollection = turf.featureCollection([...project.stands.map(s => s.geoJSONToMap!)]);
        map.fitBounds(
          turf.bbox(turf.combine(allFeatureCollection)) as [number, number, number, number],
          { padding: 20, linear: true },
        );
      }
      map.addControl(new ScaleControl({ maxWidth: 300, unit: 'metric' }));
    },
    [map],
  );

  const { baseLayerChangedNum } = useBaseLayer(map, baseLayersETapio);
  const onStandsChange = (standsInView: EditorStand[]) => {
    const newLayers = standsInView.filter(standInView => !stands.find(o => standInView.id === o.id));
    setStands(editorStands => editorStands.concat(newLayers));
  };

  getAllStandAroundEstate(
    map,
    appModel.projects.editorStandsAll,
    baseLayerChangedNum,
    onStandsChange,
    {
      zoomFilter: 14,
      abroadDraw: 50,
    },
  );

  const saveChanges = (
    deleteIds: string[],
    newLayers: Feature[],
    editedLayers: Feature[],
  ) => handleSave(deleteIds, newLayers, editedLayers);

  const handleSave = async (
    deleteIds: string[],
    newLayers: Feature[],
    editedLayers: Feature[],
  ) => {
    const numbers: number[] = project.stands
      .map(s => +s.label)
      .sort((a, b) => a - b);
    let standNumber = numbers[numbers.length - 1] ?? 0;

    // Preparing data for sending
    const newStands: NewStandFromEditor[] = newLayers.map((s: Feature) => {
      const wkt = UtilsMap.geoJSONToGeom(s.geometry as Polygon | MultiPolygon);
      const estateId = project.id;
      const sourceStandId = Number(s.properties!.sourceStandId);
      standNumber += 1;
      return {
        sourceStandId,
        estate: estateId,
        id: s.id ?? s.properties?.number,
        number: standNumber,
        mainGroup: 1,
        geom: { wkt },
        planningArea: '',
        isInUserOfflineList: true,
      };
    });
    const editStands = editedLayers.map((s: Feature) => {
      const wkt = UtilsMap.geoJSONToGeom(s.geometry as Polygon | MultiPolygon);
      const id = s.properties!.standId;
      return { wkt, id };
    });

    // Processing results
    try {
      // Add new stands
      for (const newStand of newStands) {
        await appModel.db.setNewStand(project, newStand);
      }

      // Edit stands
      runInAction(() => {
        editStands.forEach(async (editedStand) => {
          const stand = appModel.projects.getStand(editedStand.id);
          if (stand) {
            if (stand.syncStatus === SyncStatus.add) {
              const key = DB.getName(project.id, stand.id);
              const standFromBase = await appModel.db.getStand(key);
              // @ts-ignore
              await appModel.db.setNewStand(project, { ...standFromBase.stand, geom: { wkt: editedStand.wkt } });
            } else {
              const oldValue = stand.getProp('geom', DescriptorType.geo);
              if (!oldValue?.value) return;
              stand.setValue('geom', DescriptorType.geo, {
                ...oldValue.value,
                wkt: editedStand.wkt,
              });
            }
            stand.setGeoJSON(editedStand.wkt);
          }
        });
      });

      // Delete stands
      for (const deletedStand of deleteIds) {
        const stand = appModel.projects.getStand(deletedStand);
        if (stand) {
          if (stand.syncStatus === SyncStatus.add) {
            await appModel.db.remove(DB.getName(project.id, stand.id));
            project.stands = project.stands.filter(s => s.id !== stand.id);
          } else {
            stand.setStandStatus(SyncStatus.del);
          }
        }
      }
      appModel.browser.page = { p: 'project', p1: project.id };
    } catch (e) {
      console.log(e);
    } finally {
      saveComplete(newStands);
    }
  };

  return (
    <>
      <div className={clsx(classes.container, classes.resizeCont)}>
        <ResizeListener map={map}/>
        <div className={classes.map} style={{ height: '100%' }} id={CONTAINER}/>
        <OwnLocationButton
          className={classes.buttonGPS}
          setPositionLock={setPositionLock}
          positionWatcher={positionWatcher}
          positionLock={positionLock}
        />
        <StandBorderColorChooser
          map={map}
          title={LangKey.OfflineStandBorderColor.t()}
          classes={{
            root: classes.borderChooser,
          }}
        />
        <LayersControl map={map} />
      </div>
      <ViewEditorMap
        map={map}
        stands={stands}
        functions={['ADD', 'TOP', 'BOTTOM', 'CANCEL', 'DELETE']}
        waitingSave={waitingSave}
        saveChanges={saveChanges}
        bordersEstates={appModel.projects.map(project => project.geoJSON)}
        idSelectedStand={selectedStand}
        estateId={+project.id}
        changeActions={changeActions}
        baseLayerChangedNum={baseLayerChangedNum}
        buttonClasses={{
          container: classes.containerButtons,
        }}
      />
      <Compass map={map} />
    </>
  );
});

export default EditorMap;
