import * as React from 'react';
import { Map as BMap } from 'mapbox-gl';
import { LangKey } from '../../LangKey';
import { vectorLayers, vectorLayersOffline } from './vectorLayers';
import { mwsLayers, mwsLayersOffline } from './mwsLayers';
import { RasterAndVectorLayersControl } from '@simosol/layers-control';
import { appModel } from '../../models/App';

type Props = {
  map: BMap | undefined;
};

const LayersControl = (props: Props) => {
  const { map } = props;

  for (const layer in mwsLayers) mwsLayers[layer].label = mwsLayers[layer].label.t();
  for (const layer in vectorLayers) vectorLayers[layer].label = vectorLayers[layer].label.t();
  for (const layer in vectorLayersOffline) vectorLayersOffline[layer].label = vectorLayersOffline[layer].label.t();
  for (const layer in mwsLayersOffline) mwsLayersOffline[layer].label = mwsLayersOffline[layer].label.t();

  return (
    <RasterAndVectorLayersControl
      map={map}
      vectorLayersTitle={LangKey.MapVectorLayersTitle.t()}
      vectorLayers={appModel.isOffline ? vectorLayersOffline : vectorLayers}
      wmsLayersTitle={LangKey.MapMwsLayersTitle.t()}
      wmsLayers={appModel.isOffline ? mwsLayersOffline : mwsLayers}
    />
  );
};

export default LayersControl;
