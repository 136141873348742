import * as React from 'react';
import { observer } from 'mobx-react';
import * as turf from '@turf/turf';
import {
  MapButton,
  Stand as MapStand,
  usePositionWatcher,
  usePositionLock,
  useOwnLocationMarker,
  useBaseLayer,
  useMap,
  ZoomButtons,
  BaseLayerButton,
  useMapDefaultStyles,
  useStandClick,
  useMapFitBoundsAndSave,
  GpsErrorDialog,
  useOptimizedStandLayers,
} from '@simosol/stands-map';

import { appModel } from '../../models/App';
import Close from '@mui/icons-material/Close';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { Theme } from '@mui/material';
import Project from '../../models/Project';
import { Stand } from '../../models/Stands';
import ZoomToSelectedStands from './ZoomToSelectedStands';
import baseLayers from './baseLayers';
import baseLayersETapio from './etapio/baseLayersETapio';
import { LangKey } from '../../LangKey';
import { FreeGeometry } from '../../models/FreeGeometries';
import useFreeGeometries from './etapio/useFreeGeometries';
import OwnLocationManager from './OwnLocationManager';
import ResultSnackBar from '../app/ResultSnackBar';
import { MapboxDrawLine } from './MapboxDrawer';
import { UtilsMap } from '../../utils/UtilsMap';
import { MapElement } from '../../models/map/MapElement';
import FGShowMapButton from './etapio/FGShowMapButton';
import LayersControl from './LayersControl';
import proj4 from 'proj4';

const useStyles = makeStyles((theme: Theme) => createStyles({
  closeButton: {
    position: 'absolute',
    width: theme.spacing(6),
    height: theme.spacing(6),
    left: theme.spacing(1),
    top: theme.spacing(1),
    padding: theme.spacing(1.5),
    cursor: 'pointer',
  },
  buttonLocation: {
    position: 'absolute',
    right: theme.spacing(1),
    bottom: theme.spacing(10),
    borderRadius: theme.spacing(3),
  },
  assignedToMeRoot: {
    position: 'absolute',
    left: theme.spacing(1),
    top: theme.spacing(8),
    borderRadius: theme.spacing(3),
    width: theme.spacing(6),
    height: theme.spacing(6),
    cursor: 'pointer',
  },
  assignedToMeButton: {
    padding: theme.spacing(1.5),
  },
}));

type Props = {
  mapStands: MapStand[],
  onChange: (stand?: MapStand) => void,
  currentProject?: Project,
  currentStand?: Stand,
  mapGeometry?: FreeGeometry,
  gpsRecording?: boolean;
  mapElements?: MapElement[];
};

const ProjectStandsMap = observer((props: Props) => {
  const { mapStands, currentProject, onChange, currentStand, mapGeometry, gpsRecording, mapElements } = props;

  const styles = useStyles();
  const classes = useMapDefaultStyles();

  for (const layer in baseLayers) {
    baseLayers[layer].name = baseLayers[layer].name.t();
  }

  const { map, mapContainer } = useMap(
    classes.mapContainer,
    {
      container: 'map',
      accessToken: process.env.REACT_APP_MAPBOX_TOKEN,
      zoom: 15,
      minZoom: appModel.isOffline ? 12 : undefined,
      center: [23.7278, 61.4951],
      dbName: 'mapboxIDB',
      dbStore: 'tiles',
    },
  ) as { map: mapboxgl.Map | undefined, mapContainer: JSX.Element };

  const gpsModel = appModel.gps;
  const {
    baseLayer,
    setBaseLayer,
    baseLayerChangedNum,
  } = useBaseLayer(map, appModel.isTapio ? baseLayersETapio : baseLayers);

  useOptimizedStandLayers(map, mapStands, baseLayerChangedNum, { zoomFilter: 14.01 });
  useStandClick(map, onChange, baseLayerChangedNum);

  /** GPS **/
  const positionWatcher = usePositionWatcher();
  const [positionLock, setPositionLock] = usePositionLock(map, positionWatcher);
  useOwnLocationMarker(map, positionWatcher, classes.locationMarker);
  useMapFitBoundsAndSave(map, mapStands, `iptim_mobile_map_bounds_${currentProject?.name}`, false);
  useFreeGeometries({ map, mapGeometry, baseLayerChangedNum });

  const onMapInitiated = () => {
    const stands: Stand[] | undefined = currentProject
      ? currentProject.selectedStands.length
        ? currentProject.selectedStands
        : currentProject?.stands
      : currentStand
        ? [currentStand]
        : [];

    if (map) {
      if (stands.length > 0) {
        map.fitBounds(
          turf.bbox(turf.combine(turf.featureCollection(
            [...stands.map(s => s.geoJSON)],
          ) as any)) as [number, number, number, number],
          { padding: 20, linear: true },
        );
      }
      mapElements?.map(v => MapboxDrawLine(map, v.data.coords, +v.id + 1000000));
    }
  };

  map?.on('load', onMapInitiated);
  React.useEffect(
    () => {
      return () => {
        map?.off('load', onMapInitiated);
      };
    },
    [],
  );

  const onStartTrackRecording = !gpsRecording ? undefined : () => {
    gpsModel.createTracking(
      positionWatcher,
      (points) => {
        if (!points.length) {
          ResultSnackBar.error(LangKey.GpsTrackPointsErrorMessage);
          return;
        }
        if (map) MapboxDrawLine(map, points, gpsModel.lineId);
      },
    );
  };

  const onStopTrackRecording = () => {
    const featuresXml: string[] = [];
    for (const coords of gpsModel.allLines) {
      const model = appModel.mapElements.createNew({ coords });
      const feature = UtilsMap.createXMLFeatureFromModel(model);
      if (feature) {
        model.pushDB();
        featuresXml.push(feature);
      }
    }

    if (featuresXml.length) {
      UtilsMap.exportXMLFeatures(featuresXml);
    }
  };

  const onSaveGPSLocation = () => {
    const pos = positionWatcher.position?.coords as GeolocationCoordinates | undefined;
    let result = false;
    if (pos) {
      const model = appModel.mapElements.createNew(
        { coords: [proj4('EPSG:3067').forward([pos.longitude, pos.latitude])] },
      );
      const feature = UtilsMap.createXMLFeatureFromModel(model);
      if (feature) {
        model.pushDB();
        UtilsMap.exportXMLFeatures([feature]);
        result = true;
      }
    }

    if (!result) {
      ResultSnackBar.error(LangKey.GpsTrackPointsErrorMessage);
    }
  };

  return (
    <>
      {mapContainer}
      {appModel.isTapio
        ? <LayersControl map={map} />
        : <BaseLayerButton
          className={classes.buttonLayers}
          allLayers={baseLayers}
          baseLayer={baseLayer}
          setBaseLayer={setBaseLayer}
        />
      }
      <ZoomToSelectedStands
        map={map}
        stands={mapStands}
        currentStand={currentStand}
        mapGeometry={mapGeometry}
      />
      <OwnLocationManager
        onSaveGPSLocation={onSaveGPSLocation}
        onStartTrackRecording={onStartTrackRecording}
        onStopTrackRecording={onStopTrackRecording}
        className={styles.buttonLocation}
        setPositionLock={setPositionLock}
        positionWatcher={positionWatcher}
        positionLock={positionLock}
      />
      <ZoomButtons
        map={map}
        className={classes.buttonsZoom}
      />
      <GpsErrorDialog
        positionWatcher={positionWatcher}
      />
      <MapButton className={styles.closeButton}>
        <Close onClick={() => appModel.map.back()}/>
      </MapButton>
      {!appModel.isTapioIsEstates &&
        <FGShowMapButton
          className={ {
            root: styles.assignedToMeRoot,
            button: styles.assignedToMeButton,
          } }
        />
      }
    </>
  );
});

export default ProjectStandsMap;
