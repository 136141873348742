import PouchDB from 'pouchdb';
// import PutDocument = PouchDB.Core.PutDocument;
import { appModel, DApp } from './App';
import { action, toJS } from 'mobx';
import { DProject } from './Projects';
import { DDataNode } from '@simosol/iptim-data-model';
import { Stand, SyncStatus } from './Stands';
import { DMapElement } from './map/MapElement';
import Project from './Project';
import { NewStandFromEditor } from '../views/editor/EditorMap';

export const DIVIDER = '_%%%_';

export default class DB {
  private _projectsDocId = 'appData';
  private _projectsDBName = 'projects';
  private _standsDBName = 'projects_stands';
  private _mapElementsDBName = 'mapElements';
  private _maps = 'maps';

  private _createNew = <T extends {}>(dbName: string, size?: number) => {
    return new PouchDB<T>(dbName, { size, auto_compaction: true });
  }
  private _dbProjects = this._createNew(this._projectsDBName);
  private _dbStands = this._createNew(this._standsDBName);
  private _dbMapElements = this._createNew<{ data: DMapElement }>(this._mapElementsDBName);
  private _dbStandsLayers = this._createNew<{ layerIds: number[] } >(this._maps);

  getStandsLayers = async () => {
    const docs = await this._dbStandsLayers.allDocs({ include_docs: true });
    return docs.rows.map((v) => {
      return {
        id: v.id,
        layers: v.doc?.layerIds ?? [],
      };
    });
  }

  pushMapLayers = (rowsIds: Stand['id'][], layerIds: number[]) => {
    const db = this._dbStandsLayers;
    rowsIds.map((rowId) => {
      // console.log(rowId);

      db.get(rowId)
        .then((doc) => {
          const newArray = Array.from(new Set([...doc.layerIds, ...layerIds]));
          return db.put({
            _id: rowId,
            _rev: doc._rev,
            layerIds: newArray,
          });
        })
        .catch(() => {
          db.put({
            layerIds,
            _id: rowId,
          });
        });
    });
  }

  pushMapElement = (data: DMapElement) => {
    this._updateOrInsert(this._dbMapElements, { data, _id: data.id });
  }

  getMapElements = async () => {
    const elements: DMapElement[] = [];
    try {
      const docs = await this._dbMapElements.allDocs({ include_docs: true });
      docs.rows.map((v) => {
        if (v.doc?.data) elements.push(v.doc.data);
      });
    } catch (e) {

    }

    return elements;
  }

  get = async () => {
    const projectsRes = await this._dbProjects.get<{ data: DApp }>(this._projectsDocId);
    const appData = projectsRes.data;
    const allStandsDB = await this._dbStands.allDocs<{ stand: DDataNode, syncStatus: string }>({ include_docs: true });
    const projectStands: {[key: string]: DDataNode[]} = {};

    for (const standRow of allStandsDB.rows) {
      if (!standRow.doc) continue;
      const id = standRow.doc._id;
      const idParts = id.split(DIVIDER);
      const projectId = idParts[0];
      if (!projectStands[projectId]) projectStands[projectId] = [];
      projectStands[projectId].push({ ...standRow.doc.stand, syncStatus: standRow.doc.syncStatus });
    }
    for (const project of appData.projects) {
      if (projectStands[project.uid]) {
        project.data = projectStands[project.uid];
      }
    }

    return appData;
  }

  remove = async (rowId: string) => {
    // remove from stands DB
    await this._remove(this._dbStands, rowId);
    // remove layers
    await this._remove(this._dbStandsLayers, rowId);
  }

  /**
   * remove row from DB
   * @param db
   * @param id
   */
  private _remove = async <T extends {}>(db: PouchDB.Database<T>, id: string) => {
    const doc = await this.getDoc(db, id);
    if (doc) db.remove(doc);
  }

  /**
   * Get DB doc
   * @param db
   * @param id
   */
  private getDoc = async <T extends {}>(db: PouchDB.Database<T>, id: string) => {
    return new Promise<(PouchDB.Core.IdMeta & PouchDB.Core.GetMeta) | undefined>(async (resolve) => {
      try {
        resolve(await db.get(id));
      } catch (e) {
        resolve(undefined);
      }
    });
  }

  updateOrInsert = async (data: DApp) => {
    const newData = { ...toJS(data) };
    const projectsWithoutStands: DProject[] = [];
    let allStandRows = undefined;
    try {
      allStandRows = await this._dbStands.allDocs<{ stand: DDataNode, syncStatus: string }>({ include_docs: true });
    } catch (e: any) {
      if (e.status !== 404) {
        throw (e);
      }
    }

    const standDocs: { _id: string, stand: DDataNode, syncStatus?: string, _rev?: string }[] = [];
    for (const project of newData.projects) {
      const stands = project.data;
      projectsWithoutStands.push({ ...project, data: [] });
      for (const stand of stands) {
        const rowId = DB.getName(project.uid, stand.id as string);
        // const rowId = project.uid + '_%%%_' + stand.uid;
        let rev = undefined;
        let syncStatus: string | undefined;
        if (allStandRows) {
          for (const standRow of allStandRows.rows) {
            if (standRow.id === rowId) {
              rev = standRow.value.rev;
              syncStatus = standRow.doc?.syncStatus;
              break;
            }
          }
        }
        standDocs.push({ stand, syncStatus, _id: rowId, _rev: rev });
      }
    }
    const standDocsSlices: ({ _id: string, stand: DDataNode, syncStatus?: string }[])[] = [];
    const chunk = 10;
    for (let i = 0, j = standDocs.length; i < j; i += chunk) {
      standDocsSlices.push(standDocs.slice(i, i + chunk));
    }

    for (const standDocsSlice of standDocsSlices) {
      await this._dbStands.bulkDocs(standDocsSlice);
    }

    newData.projects = projectsWithoutStands;
    return this._updateOrInsert(this._dbProjects, { data: newData, _id: this._projectsDocId });
  }

  destroyAndCreateNew = async () => {
    await this._dbProjects.destroy();
    await this._dbStands.destroy();
    this._dbProjects = this._createNew(this._projectsDBName);
    this._dbStands = this._createNew(this._standsDBName);
  }

  /**
   * update or insert data into DB
   */
  private _updateOrInsert = async <T extends { _id: string, _rev?: string }>
  (db: PouchDB.Database, doc: T) => {
    appModel.syncIntoDB = true;
    try {
      const dbDoc = await db.get(doc._id);
      doc._rev = dbDoc._rev;
    } catch (e) {
      // TODO handle critical errors
    }
    await db.put(doc as PouchDB.Core.PutDocument<T>);
    appModel.syncIntoDB = false;
    return doc;
  }

  setStandStatus = async (stand: Stand, syncStatus: SyncStatus) => {
    const doc = await this._dbStands.get(DB.getName(stand.project.id, stand.id));
    if (!doc) return;
    await this._dbStands.put({ ...doc, syncStatus });
    stand.syncStatus = syncStatus;
  }

  @action
  setNewStand = async (
    project: Project,
    stand: NewStandFromEditor,
  ) => {
    await this._updateOrInsert(this._dbStands, {
      stand,
      syncStatus: SyncStatus.add,
      _id: DB.getName(project.id, stand.id),
    });
    appModel.instanceControl.setNewInstance(stand.id);
  }

  getStand = async (id: string) => {
    return await this.getDoc(this._dbStands, id);
  }

  setNewFullStand = async (
    id: string,
    stand: DDataNode,
  ) => {
    await this._updateOrInsert(this._dbStands, { stand, _id: id });
  }

  getUnsavedStands = async () => {
    const allStandsDB = await this._dbStands.allDocs<{ stand: NewStandFromEditor }>({ include_docs: true });
    const deletedStands: Stand[] = [];
    const addedStands: NewStandFromEditor[] = [];
    for (const standRow of allStandsDB.rows) {
      if (!standRow.doc) continue;
      if (standRow.doc['syncStatus'] === SyncStatus.del) {
        const standId = standRow.doc.stand['id'] as string;
        if (standId === undefined) return;
        const stand = appModel.projects.getStand(`${standId}`);
        if (stand) deletedStands.push(stand);
      }
      if (standRow.doc['syncStatus'] === SyncStatus.add) {
        addedStands.push(standRow.doc.stand);
      }
    }
    return { deletedStands, addedStands };
  }

  static getName = (prefix: string | number, suffix: string | number) => `${prefix}${DIVIDER}${suffix}`;
}
